import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Marquee from "react-fast-marquee";
import { InputCard } from './InputCard';
import {OutputCard} from './outputCard'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import axios from 'axios';


const AWS_HEROKU_AJAX = () => {
  return axios.create({
    baseURL: "https://amazon-aws-bot.herokuapp.com",
    headers: {
      "Access-Control-Allow-Origin": "*"
    }
  })
}


const AWS_HEROKU_AJAX_JSON = () => {
  return axios.create({
    baseURL: "https://amazon-aws-bot.herokuapp.com",
    headers: {
      'Content-Type': 'application/json'
    }
  })
}



async function getBaseData() {
  return (await AWS_HEROKU_AJAX().get("")).data;  
}

const App = () => {

  const {
    transcript,
    listening,
    resetTranscript,
  } = useSpeechRecognition();


  const [outputMessage, setoutputMessage] = useState("");

  const [prevMessage, setprevMessage] : any = useState([]);

  async function checkForCall() {
    await getBaseData();    
    if(!listening && transcript !== ""){

      try {
      let postData = await AWS_HEROKU_AJAX_JSON().post("/message/find", {msg: transcript});
      const responseData = await postData.data;
      console.log(responseData);
      const speakableMessage = responseData[0]['messageResponses'][0];
      console.log({msg: 'this is triggered'});
      setoutputMessage(speakableMessage);
      let utterance = new SpeechSynthesisUtterance(speakableMessage.split("<")[0]);
      var voices = window.speechSynthesis.getVoices()
      utterance.voice = voices.filter(voice => voice.name === 'Fiona')[0]
      utterance.lang = 'en-US';
      speechSynthesis.speak(utterance);
      setprevMessage([{
        human: transcript,
        bot: speakableMessage,
      }, ...prevMessage]);
      setoutputMessage("");
      resetTranscript();
      } catch (error) {
        console.error(error);
      }
      
    }
  }


  useEffect(()=> {
      checkForCall();
  }, [listening, transcript])


  async function playSound(){
    setoutputMessage("");
    resetTranscript();
    SpeechRecognition.startListening();
    let audio = new Audio('play.wav');
    audio.play();

  }
  return <> 
  <div className=' shadow-md'>
  <div className="flex">
  <div className="m-auto">
  <img src="voice2.gif" style={{width: '90%'}} alt='' className=' cursor-pointer rounded-full shadow-lg w-full transition ease-in-out hover:-translate-y-1 hover:scale-110' onClick={playSound}/>
  </div>
</div>
<div className="flex">
  <div className="m-auto">
  <InputCard text={transcript}/>
  <br/><br/>
  <OutputCard text={outputMessage}/>
  <br/><br/>

  {prevMessage.map((item: any)=> {
    return <div>
       <InputCard text={item.human}/>
      <br/><br/>
      <OutputCard text={item.bot}/>
      <br/><br/>
      </div>
  })}

</div>
</div>
  </div>


  </>
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
function data(arg0: string, data: any, arg2: {}): any {
  throw new Error('Function not implemented.');
}

